.breadcrumbs {
  position: relative;
}

.breadcrumbsItem:after {
  content: '';
  display: inline-block;
  position: relative;
  @apply bg-neutral-80;
  margin-top: -5px;
  margin-left: 14px;
  height: 12px;
  width: 8px;
  clip-path: path(
    'M5.89915 5.74413C6.03362 5.88821 6.03362 6.11179 5.89915 6.25587L0.649146 11.8809C0.507833 12.0323 0.270537 12.0405 0.119131 11.8991C-0.0322752 11.7578 -0.0404577 11.5205 0.100855 11.3691L5.11204 6L0.100855 0.630871C-0.0404577 0.479464 -0.0322752 0.242168 0.119131 0.100856C0.270537 -0.040457 0.507833 -0.0322745 0.649146 0.119132L5.89915 5.74413Z'
  );
}

@media print {
  .isPrivateArea {
    display: none;
  }
}
