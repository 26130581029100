.alerts {
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.barContainer {
  transition: opacity 0.5s ease-out;
}

.bar {
  height: 4px;
  -webkit-animation: move 4.2s;
  animation: move 4.2s forwards;
}

.animationIn {
  animation: in 0.5s ease-in forwards;
}

.animationOut {
  animation: out 0.5s ease-in forwards;
}

@keyframes in {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 90px, 0);
  }
}
@keyframes out {
  0% {
    transform: translate3d(0, 90px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0;
  }
}

@-webkit-keyframes move {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes move {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
