.select {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    right: 1px;
    width: 14px;
    height: 9px;
    background-image: url('/svg/icons/arrow_select.svg');
    background-repeat: no-repeat;
    background-position: 100% center;
    pointer-events: none;
  }

  &.disabled:after {
    background-image: url('/svg/icons/arrow_select_disable.svg');
  }

  & select {
    @apply w-full;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 16px;
  }
}

.phoneInput {
  grid-template-columns: 1fr 3fr;
}
